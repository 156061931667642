<template>
  <div>
    <div class="caption grey--text text--darken-1">Total: {{ geneListResult.length }} genes</div>

    <div class="mt-2 d-flex flex-row">
      <div>
        <v-data-table
          :hidden="isTableMinimized"
          sort-by="rounded_score_scatter_variant"
          :sort-desc="true"
          :items-per-page="20"
          :page.sync="page"
          @page-count="pageCount = $event"
          hide-default-footer
          :loading="loading"
          loading-text="Retrieving the analysis result ..."
          ref="detailGrid"
          :headers="headers"
          :items="geneListResult"
          @click:row="clickRow"
          class="elevation-1 mb-8"
          :item-key="'name'"
          single-select
          multi-sort
          v-model="selected"
          :style="'width: ' + dataTableWidth"
        >
        <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ }">
          <span :key="'hed3-'+h"  >
            <span>{{h.text}} </span>
            <Help section="GENE" :lookupKey="h.text"></Help>      
          </span>
        </template>
          <template v-slot:item.name="{ item }">
            <GeneInfo class="mb-1" :key="item.name" :id="item.name"></GeneInfo>
          </template>
          <template v-slot:item.ORGANIZATION="{ item }">
            <OrganizationComments v-if="item.ORGANIZATION.length > 0" :comments="item.ORGANIZATION"></OrganizationComments>
          </template>
          <template v-slot:item.info.omimInfo="{ item }">
            <OmimInheritanceList :compact="true" :info="item.info.omimInfo"></OmimInheritanceList>
          </template>
          <template v-slot:item.NOTE="{ item }">
            <CohortAnalysisGeneNote :gene="item.name" :cohortId="cohortId" :projectId="projectId" :note="item.__usr_label?.note || null"></CohortAnalysisGeneNote>
          </template>
          <template v-slot:item.rounded_score_scatter_variant="{ item }">
            <span>{{ item.rounded_score_scatter_variant }}</span>
            <v-tooltip bottom v-if="item.rounded_score_scatter_variant == 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mb-1" v-bind="attrs" v-on="on" small color="orange">mdi-alert-outline</v-icon>
              </template>
              <span>No variant has been found for {{ item.name }} with the filters</span>
            </v-tooltip>
          </template>
          <template v-slot:item.label="{ item }">
            <v-menu offset-y :rounded="false" transition="slide-y-transition" bottom right style="box-shadow: none !important">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on"> 
                  <v-btn v-if="!isTableCollapsed" class="mx-n1" small elevation="0" :color="geneLabelColors[item.label?.id] || 'grey'" outlined  width="120px">
                    {{ item.label?.title || "" }} <v-icon small class="ml-auto">mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-icon v-else :color="geneLabelColors[item.label?.id] || 'primary'"> {{ item.label?.id? 'mdi-tag' : 'mdi-tag-outline'}}</v-icon>

                </div>
              </template>
              <v-list dense outlined>
                <v-list-item style="min-height: 35px !important" class="body-2" dense v-for="lbl in geneLabels" rounded :key="lbl.id" @click="onMarkTag(item, lbl)">
                  <v-icon class="mr-1" small v-if="(item.label?.id || '') == lbl.id">mdi-check</v-icon>
                  <div style="width: 20px"></div>
                  {{ lbl.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data> No Gene has been found </template>
        </v-data-table>
        <v-pagination v-if="!isTableMinimized" circle v-model="page" :length="pageCount" :total-visible="6"></v-pagination>
      </div>
      <div class="d-flex flex-column " v-if="isTableCollapsed || isTableMinimized">
        <div class="primary" style="height: 48px">
          <v-btn class="mt-1" icon @click="onMinimizeChanged"
            ><v-icon color="white"> {{ isTableMinimized ? "mdi-chevron-double-right" : "mdi-chevron-double-left" }} </v-icon></v-btn>
        </div>
        <div style="border: 1px solid #eeeeee" class="flex-grow-1 mb-8"></div>
      </div>

      <div v-if="isTableCollapsed" class="ml-4 geneDetailBox ">
        <div class="accent pa-4 text-h6 d-flex flex-row">
          <div>
            <b>{{ selectedGene.name }} </b>
          </div>
          <div class="ml-auto">
            <v-btn icon @click="onCloseDetails"><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </div>
        <div class="pa-6">
          <CohortAnalysisGeneDetails ref="CohortAnalysisGeneDetails" :datasets="datasets" :cohortId="cohortId" :projectId="projectId" :filters="geneIncludedFilters" :filterLabels="filterLabels" :gene="selectedGene" ></CohortAnalysisGeneDetails>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProjectGeneLabel, setProjectGeneLabel } from "@/api/user/cohort.js";
import { getProjectGenes } from "@/api/user/cohort";
import GeneInfo from "@/components/defaultAnalysis/GeneInfo.vue";
import CohortAnalysisGeneNote from "@/components/CohorAnalysis/CohortAnalysisGeneNote.vue";
import CohortAnalysisGeneDetails from "@/components/CohorAnalysis/CohortAnalysisGeneDetails.vue";
import OrganizationComments from "@/components/OrganizationComments.vue";
import OmimInheritanceList from "@/components/CohorAnalysis/OmimInheritanceList.vue";
import Help  from "@/components/Common/Help.vue";

export default {
  name: "ViewCohortAnalysisGeneResult",
  components: { GeneInfo, CohortAnalysisGeneNote, OrganizationComments, CohortAnalysisGeneDetails, OmimInheritanceList, Help },
  props: {
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    filterLabels: {
      type: Array,
      required: true,
    },
    selectedGenes: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    selected: [],
    variantLabels: [],
    loading: true,
    geneLabels: [],
    geneLabelColors: {
      1: "#00CC88", //KEEP
      2: "#ee4949", //DISCARD
      3: "#615D69", //DON'T KNOW
      4: "orange", //True with unclear significance
    },
    rawGeneListResult: null,
    isTableCollapsed: false,
    isTableMinimized: false,
    selectedGene: null,
  }),
  computed: {
    dataTableWidth() {
      if (this.isTableMinimized) {
        return "50px";
      } else if (this.isTableCollapsed) {
        return "350px";
      } else {
        return "100%";
      }
    },
    geneIncludedFilters() {
      let tmpFilters = JSON.parse(JSON.stringify(this.filters)); // Object.assign({}, this.filters) SHALOW COPY
      // console.log("tmpFilters--", tmpFilters);

      Object.keys(tmpFilters).forEach((dsType) => {
        const geneFieldName = this.datasets[dsType].fields.find((item) => item.title == "OVERLAPPING_GENES").name;
        let geneFilter = {};
        geneFilter[geneFieldName] = [this.selectedGene.name];

        tmpFilters[dsType].inFilters.push(geneFilter);
      });
      // console.log("tmpFilters", tmpFilters);
      return tmpFilters;
    },
    headers() {
      if (this.isTableCollapsed) {
        return [
          { text: "Label", align: "center", sortable: false, value: "label", class: "primary white--text font-weight-meduim text-center pr-1 pl-2", cellClass: "variantCell px-1",width: "15px" },
          { text: "Note", value: "NOTE", class: "primary white--text  pr-1 pl-0", cellClass: "variantCell pr-1 pl-0", sortable: false, width: "20px", align: "center" },
          { text: this.organizationName, value: "ORGANIZATION", divider: true, class: "primary white--text px-0", cellClass: "variantCell  px-0", sortable: false, width: "20px", align: "center" },
          { text: "Gene", align: "center", sortable: false, value: "name", class: "primary white--text font-weight-meduim text-center  px-0", cellClass: "variantCell px-0", width:"20px" },
          { text: "OMIM Disease Inheritance", width: 100, align: "center", sortable: false, value: "info.omimInfo", class: "primary white--text font-weight-meduim text-center  px-0", cellClass: "variantCell  px-1",  },
        ];
      } else {
        return [
          { text: "Label", align: "center", sortable: false, value: "label", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "Note", value: "NOTE", class: "primary white--text ", cellClass: "variantCell", sortable: false, width: "20px", align: "center" },
          { text: this.organizationName, value: "ORGANIZATION", divider: true, class: "primary white--text ", cellClass: "variantCell", sortable: false, width: "20px", align: "center" },
          { text: "Chromosome", align: "center", sortable: false, value: "chr", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "Start", align: "center", sortable: false, value: "start", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "End", align: "center", sortable: false, value: "end", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "Gene", align: "center", sortable: false, value: "name", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "OMIM Disease Inheritance", width: 180, align: "center", sortable: false, value: "info.omimInfo", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "Score Scatter Variant", width: 180, align: "center", sortable: true, value: "rounded_score_scatter_variant", class: " text-no-wrap primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "Haploinsufficiency", width: 180, align: "center", sortable: true, value: "info.SCORES.Haploinsufficiency", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "LOEUF", align: "center", sortable: true, value: "info.SCORES.LOEUF", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "Triploinsufficiency", align: "center", sortable: true, value: "info.SCORES.Triploinsufficiency", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
          { text: "pLI", align: "center", sortable: true, value: "info.SCORES.pLI", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
        ];
      }
    },
    organizationName() {
      // console.log('store',this.$store.state)
      return this.$store.state.Organization.name;
    },
    geneListResult() {
      if (this.rawGeneListResult == null) {
        return [];
      }
      return this.rawGeneListResult.map((item) => {
        let x = item;
        x.label = item.__usr_label?.label || null ? this.geneLabels.find((l) => l.id == item.__usr_label.label) : null;
        x.ORGANIZATION =
          item.__organization_label === null
            ? []
            : item.__organization_label.map((usr) => {
                let p = {};
                (p.first_name = usr.first_name), (p.last_name = usr.last_name), (p.label = usr.label !== null ? this.geneLabels.find((l) => l.id == usr.label).title || null : null), (p.labelColor = this.geneLabelColors[usr.label] || "black");
                p.note = usr.note;
                return p;
              });
        x.rounded_score_scatter_variant = Math.round(item.SCORE_SCATTER_VARIANT * 1000) / 1000;
        x.info["omimInfo"] = x.info?.OMIM || [];
        return x;
      });
    },

    selectedGeneTitle() {
      const value = " [" + this.selectedGenes.join(" ,") + "] ";
      return `<b class="pr-1">${this.selectedGenesType} </b> ${value}`;
    },
  },
  created() {
    const _this = this;
    getProjectGeneLabel((res) => {
      _this.geneLabels = res;
    });
    // this.loading = true;
    // this.reset();
    // console.log(f)
    // this.showFilters = false;
    // this.loadGenes();
  },

  methods: {
    onCloseDetails() {
      this.isTableCollapsed = false;
      this.isTableMinimized = false;
    },
    onMinimizeChanged() {
      this.isTableMinimized = !this.isTableMinimized;

      this.$refs.CohortAnalysisGeneDetails.redraw();
    },
    update() {
      this.loading = true;
      this.isTableCollapsed = false;
      this.isTableMinimized = false;
      this.reset();
      this.loadGenes();
    },
    onMarkTag(item, lbl) {
      const _this = this;
      setProjectGeneLabel(this.cohortId, this.projectId, item.name, lbl.id, `${item.name} marked as ${lbl.title}`, () => {
        const idx = _this.rawGeneListResult.findIndex((g) => g.name == item.name);
        if (_this.rawGeneListResult[idx].__usr_label) {
          _this.rawGeneListResult[idx].__usr_label["label"] = lbl.id;
        } else {
          _this.rawGeneListResult[idx].__usr_label = { label: lbl.id };
        }
        // console.log(_this.rawAnalysisResult[idx]);
      });
    },
    reset() {
      this.rawGeneListResult = [];
      this.selected = [];
    },
    // clickRow(item, row) {
    clickRow(item, row) {
      this.isTableCollapsed = true;

      this.selectedGene = item;
      row.select(true);
    },
    loadGenes() {
      const _this = this;
      const payload = {
        genes: this.selectedGenes,
        sv_filters: this.filters.SV,
        snv_filters: this.filters.SNV,
      };
      // console.log(payload);
      getProjectGenes(this.cohortId, this.projectId, payload, (res) => {
        _this.rawGeneListResult = res;
        _this.loading = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep tr.v-data-table__selected {
  background: var(--v-accent-base) !important;
  border-bottom: 0px !important;
}

.theme--light.v-data-table::v-deep {
  background-color: transparent;
}
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td.variantCell {
  height: 45px !important;
  border-bottom: 1px solid #0393a850 !important;
}



.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__expanded__content):hover {
  cursor: pointer;
}
.temp{
  color: #ee4949;
}


.geneDetailBox {
  width: 100%;
  border: 1px var(--v-accent-base) solid;
}
</style>