<template>
  <div class="d-flex flex-row rounded white" :class="isPinned ? 'stickyPatientInfo' : ''">
    <v-card flat width="30%"  outlined >
      <v-card-title class="grey lighten-3 font-weight-bold pt-2" style="height: 50px !important">
        <div><v-icon class="mr-1">mdi-tag-text-outline</v-icon> Details</div>
        <!-- <v-icon class="ml-auto" @click="isPinned.details = !isPinned.details">{{ isPinned.details ? "mdi-pin-off-outline" : "mdi-pin-outline" }}</v-icon> -->
      </v-card-title>
      <v-card-text class="px-1 body-1">
        <v-row dense class="mt-2 px-4">
          <v-col cols="5" class="font-weight-bold py-1">Case Name: </v-col>
          <v-col cols="7"
            >{{ sample.sampleName }} - <span :style="'color:' + getDiseaseColor(sampleInfo.DISEASE_STATUS)">{{ sampleInfo.DISEASE_STATUS }}</span>
          </v-col>
        </v-row>
        <v-row dense class="grey lighten-3 px-4 py-1">
          <v-col cols="5" class="font-weight-bold">Reference Genome</v-col>
          <v-col cols="7">GRCh38</v-col>
        </v-row>
        <!-- <v-row dense class="px-4 py-1">
                    <v-col cols="4" class="font-weight-bold" >Analysis Status</v-col>
                    <v-col cols="8" class="font-weight-medium" :style="'color:'+sample.hrStatus.color">{{ sample.hrStatus.msg }} {{(sample.decision ? '- '+ sample.decision.title: null)  }}</v-col>
                </v-row> -->
        <v-row dense class="px-4 py-1">
          <v-col cols="5" class="font-weight-bold">Father</v-col>
          <v-col cols="7"
            >{{ fatherInfo.sampleId }} - <span :style="'color:' + getDiseaseColor(fatherInfo.status)"> {{ fatherInfo.status }}</span>
          </v-col>
        </v-row>
        <v-row dense class="grey lighten-3  px-4 py-1">
          <v-col cols="5" class="font-weight-bold">Mother</v-col>
          <v-col cols="7"
            >{{ motherInfo.sampleId }} - <span :style="'color:' + getDiseaseColor(motherInfo.status)"> {{ motherInfo.status }}</span></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat width="40%" class="ml-4" outlined >
      <v-card-title class="grey lighten-3 font-weight-bold d-flex flex-row pt-2" style="height: 50px !important">
        <div><v-icon class="mr-1">mdi-tag-text-outline</v-icon> Phenotypes and Disease Terms</div>
        <!-- <v-icon class="ml-auto" @click="isPinned.phenotype = !isPinned.phenotype">{{ isPinned.phenotype ? "mdi-pin-off-outline" : "mdi-pin-outline" }}</v-icon> -->
      </v-card-title>
      <v-card-text class="d-flex flex-wrap mt-4">
        <div v-for="(term, index) in terms" :key="index">
          <v-chip class="mr-2 mb-2 text-body-2 primary--text accent" label>{{ term }}</v-chip>
        </div>
      </v-card-text>
    </v-card>
    <v-card flat width="40%" class="ml-4" outlined >
      <v-card-title class="d-flex flex-row grey lighten-3 font-weight-bold pt-2" style="height: 50px !important">
        <div>
          <div>
            <v-icon class="mr-1">mdi-human-male-female-child</v-icon> Pedigree
            <v-tooltip  bottom >
              <template v-slot:activator="{on, attrs}">
                <v-icon x-small v-bind="attrs" v-on="on" class="mb-3" >mdi-help-circle-outline </v-icon>
              </template>
                <div class="grey--text text--darken-3">
                  <div class="d-flex flex-row">
                    <div class="pedigree-legend female affected"></div> Female - Affected
                    <div class="pedigree-legend female unaffected ml-12"></div> Female - Unaffected
                  </div>
                  <div class="d-flex flex-row">
                    <div class="pedigree-legend male affected"></div> Male - Affected
                    <div class="pedigree-legend male unaffected ml-16"></div> Male - Unffected
                  </div>
                  <div class="d-flex flex-row">
                  </div>
                  <span>* Use mouse scroll to zoom in/out and also mouse drag to move the pedigree</span>
                </div>
            </v-tooltip>
          </div>
        </div>
        <v-icon class="ml-auto" @click="isPinned  = !isPinned">{{ isPinned ? "mdi-pin-off-outline" : "mdi-pin-outline" }}</v-icon>
      </v-card-title>
      <PatientPedigree :sampleName="sample.sampleName" :cohortId="cohortId" :projectId="projectId"></PatientPedigree>
    </v-card>
  </div>
</template>
<script>
import { getPhenotypeHpo } from "@/api/gene/hpo.js";
import { getData } from "@/api/user/dataset";
import { getDatasetFields } from "@/api/dataset";
import { getDatasetsBySample } from "@/api/dataset.js";
import PatientPedigree from "@/components/LucidAllAnalysis/PatientPedigree.vue";

// Vue.use(VueResource);
export default {
  name: "LucidAllAnalysisPatientInfo",
  components: { PatientPedigree },
  props: {
    sample: {
      type: Object,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    }, 
  },
  data: () => ({
    terms: [],
    isPinned:  false,
    sampleInfo: {
      DISEASE_STATUS: null,
    },
  }),
  computed: {
    fatherInfo() {
      return {
        sampleId: this.sampleInfo?.FATHER || null,
        status: this.sampleInfo?.FATHER_STATUS || null,
        color: this.getDiseaseColor(this.sampleInfo.FATHER_STATUS),
      };
    },
    motherInfo() {
      return {
        sampleId: this.sampleInfo?.MOTHER || null,
        status: this.sampleInfo?.MOTHER_STATUS || null,
        color: this.getDiseaseColor(this.sampleInfo.FATHER_STATUS),
      };
    },
    hpoTerms() {
      return this.sampleInfo?.HPO_TERMS || [];
    },
  },
  watch: {
    hpoTerms() {
      this.terms = [];
      this.hpoTerms.forEach((item, index) => {
        if (item.startsWith("HP:")) {
          this.terms.push("Loading...");
          this.setHpoTitle(item, index);
        } else {
          this.terms.push(item);
        }
      });
    },
    sample() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getDiseaseColor(status) {
      if (status == "affected") {
        return "red";
      } else {
        return "grey";
      }
    },
    loadData() {
      const sampleId = this.$route.params.id;
      const _this = this;
      getDatasetsBySample(sampleId, function (res) {
        const datasetId = res[0].id || null;
        if (datasetId) {
          getDatasetFields(datasetId, function (res) {
            const sampleFieldName = res.find((ds) => ds.title == "SAMPLE")?.name || null;
            if (sampleFieldName) {
              _this.loadSampleData(sampleFieldName, datasetId);
            } else {
              _this.$root.notify.show({ message: "No column is marked as SAMPLE! Please contact support", type: "warning" });
            }
          });
        } else {
          _this.$root.notify.show({ message: "No SV/SND dataset has found", type: "error" });
        }
      });
    },
    loadSampleData(sampleFieldName, datasetId) {
      let sampleFilter = {};
      sampleFilter[sampleFieldName] = this.sample.sampleName;
      const allExactFilters = [sampleFilter];
      const columns = ["FATHER", "MOTHER", "FATHER_STATUS", "MOTHER_STATUS", "HPO_TERMS", "ID", "DISEASE_STATUS"];
      const _this = this;
      getData(this.cohortId, datasetId, allExactFilters, [], [], [], [], "one_of_all", 1, 0, [], [], [], columns, [], function (res) {
        // console.log(res.result[0])
        _this.sampleInfo = res.result[0];
      });
    },
    setHpoTitle(hpoId, index) {
      const _this = this;
      getPhenotypeHpo(hpoId, (res) => {
        // console.log(res.name)
        this.$set(_this.terms, index, res.name);
      });
    },
  },
};
</script>
<style scoped>
.box {
  /* border-radius: 16px; */
  /* border: 1px solid #95d9e3; */
  /* background:  linear-gradient(90deg, rgba(3, 146, 168, 0.137) -59.56%, rgba(3, 146, 168, 0.055) 74.32%); */
  background: white;
  /* border-style: solid; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.14) !important;
}
.chipColor {
  background-color: #7b9ebe !important;
}
.stickyPatientInfo {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14) !important;
}

.linage {
  fill: none;
  stroke: #000;
}
.marriage {
  fill: none;
  stroke: black;
}
.man {
  background-color: lightblue;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  border-radius: 10px;
}
.woman {
  background-color: pink;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}
.emphasis {
  font-style: italic;
}
svg {
  border-style: solid;
  border-width: 1px;
}

.pedigree-legend{
  width: 15px;
  height: 15px;
  border: 1px solid grey;
  margin-top: 3px;
  margin-right: 4px;
}

.affected {
  background-color: #001A55;
}

.unaffected {
  background-color: white;
}
.female {
  border-radius: 7px;
}

</style>